<template>
    <div class="field-textarea" :class="getClasses">
        <div class="form__group field">
            <textarea class="form__field" :id="id" :type="type" :name="name" :placeholder="label" ref="input" :value="value" @input="updateValue()" :disabled='disabled'>
            </textarea>
            <label :for="id" class="form__label">{{label}}</label>
            <div v-if="hasError" class="error">{{error}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FieldInput",
        props:{
            label: String,
            name: String,
            value: String|Number,
            error: String,
            type: {
                type: String,
                default: 'input'
            },
            classes: {
                default: 'col-md-6',
				type: String
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
			hasError(){
				return typeof this.error !== 'undefined' && this.error !== '';
            },
            getClasses(){
				return [
					this.hasError ? this.classes+' has-error' : this.classes
				]
			},
        },
        data () {
			return {
				id: null
			}
		},
        methods: {
            updateValue()
            {
                this.$emit('input', this.$refs.input.value)
            },
        },
    }
</script>

<style lang="scss" scoped>

    .field-textarea{
		display: block;
		width: 100%;
		margin-bottom: 1.5rem;
		&.has-error{
			textarea{
				border-bottom: 1px solid $danger;
			}
		}
		textarea{
            display: block;
            resize: none;
            height: 140px;
			::placeholder{
				color: $lightGray;
            }
		}
		.error{
			color: $danger;
            padding-left: 0px;
            font-size: 0.625rem;
		}
	}

    .form__group {
        position: relative;
        padding: 15px 0 0;
        margin-top: 0px;
    }

    .form__field {
        font-family: inherit;
        width: 100%;
        border: 0;
        border-bottom: 1px solid $lighterGray;
        outline: 0;
        font-size: 0.875rem;
        padding: 7px 0;
        background: transparent;
        transition: border-color 0.2s;

        &::placeholder {
            color: transparent;
        }

        &:placeholder-shown ~ .form__label {
            font-size: 0.875rem;
            cursor: text;
            top: 20px;
        }
    }

    .form__label {
        position: absolute;
        top: 5px;
        display: block;
        transition: 0.2s;
        font-size: 0.625rem;
        color: $lightGray;
        pointer-events: none;
    }

    .form__field:focus {
        ~ .form__label {
            position: absolute;
            top: 5px;
            display: block;
            transition: 0.2s;
            font-size: 0.625rem;
        }
        padding-bottom: 7px;
    }
    /* reset input */
    .form__field{
        &:required,&:invalid { box-shadow:none; }
    }
    .form__field:disabled {
	    color: $lightGray;
	    -webkit-text-fill-color: #93a3ab;
	    -webkit-opacity: 1;
    }
</style>
